import Cookies from "js-cookie";

export default {
  data() {
    return {};
  },
  created() {
  },
  methods: {

    async onClickPageLink(url) {
      console.log("-> onClickPageLink", url);
      if (url) {
        if (url.startsWith("http://") || url.startsWith("https://") || url.startsWith("//")) {
          window.location.href = url;
        } else if (url.startsWith("tel:")) {
          location.href = url;
        } else {
          document.documentElement.scrollTop = 0;
          this.$router.push(url);
        }
      } else {
        console.log("\r\n:-> onClickPageLink 没有找到跳转链接", url);
      }
    },
    async checkWeiXinCode(callback) {
      let backWxUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd752721da559bc0c&redirect_uri=http%3A%2F%2Fscb.eaworks.cn%2Fpage%2Fchq_code.html&response_type=code&scope=snsapi_base&state=chenghuaqu#wechat_redirect";
      let wxCode = localStorage.getItem("_wx_code");
      let queryCode = this.$route.query.code;
      if (queryCode) {
        // todo 验证有效性？
        if (queryCode.length < 4) {
          console.warn("-> queryCode错误，跳转到微信登录页面");
          window.location.href = backWxUrl;
          return;
        }
        let {data, code} = await this.$ajax.gateway("/api/api_check_code", {
          "METHOD": "get",
          "code": this.$route.query.code,
        });
        if (code === 200 && data && data.user_id) {
          localStorage.setItem("_wx_code", data.token);
          Cookies.set('chenghua_h5', data.token);
          window.location.href = "/page#/login";
        } else {
          console.warn("-> admin_check_code错误，跳转到微信登录页面");
          window.location.href = backWxUrl;
          return;
        }
      } else if (wxCode && Cookies.get('chenghua_h5')) {
        console.log("-> 获取到了微信code [从缓存中]", wxCode);
      } else {
        console.warn("-> code不存在，跳转到微信登录页面");
        window.location.href = backWxUrl;
        return;
      }
      if (callback) callback();
    },
    async checkWeiXinCodeByDetail(id, callback) {
      let backWxUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd752721da559bc0c&redirect_uri=http%3A%2F%2Fscb.eaworks.cn%2Fpage%2Fchq_detail_code.html&response_type=code&scope=snsapi_base&state=" + id + "#wechat_redirect";
      let wxCode = localStorage.getItem("_wx_code");
      let queryCode = this.$route.query.code;
      if (queryCode) {
        if (queryCode.length < 4) {
          console.warn("-> queryCode错误，跳转到微信登录页面");
          window.location.href = backWxUrl;
          return;
        }
        let {data, code} = await this.$ajax.gateway("/api/api_check_code", {
          "METHOD": "get",
          "code": this.$route.query.code,
        });
        if (code === 200 && data && data.user_id) {
          localStorage.setItem("_wx_code", data.token);
          Cookies.set('chenghua_h5', data.token);
        } else {
          console.warn("-> admin_check_code错误，跳转到微信登录页面");
          window.location.href = backWxUrl;
          return;
        }
      } else if (wxCode && Cookies.get('chenghua_h5')) {
        console.log("-> 获取到了微信code [从缓存中]", wxCode);
      } else {
        console.warn("-> code不存在，跳转到微信登录页面");
        window.location.href = backWxUrl;
        return;
      }
      if (callback) callback();
    },
  }
};
